
  
  
  import FETCHING from '../../../library/fetching'
  import UMUM from '../../../library/umum'
  import DATA_MASTER from '../../../library/dataMaster'


  import TabelRekap from './template/tabel_rekap.vue'

  
  export default {
    components: {
      TabelRekap,
    },
    data() {
      return {
  
        form : {
          id : '',
          uraian : '',
          kode : '',
          keterangan : '',
        },



        datax : {
          dikecualikan : {
            no : 1,
            uraian : 'Dikecualikan'
          },
          purchasing : {
            no : 2,
            uraian : 'e-Katalog'
          },
          pengadaan_langsung : {
            no : 3,
            uraian : 'Pengadaan langsung'
          },
          penunjukan_langsung : {
            no : 4,
            uraian : 'Penunjukan Langsung'
          },
          Seleksi : {
            no : 5,
            uraian : 'Seleksi'
          },
          tender : {
            no : 6,
            uraian : 'Tender & e-Tender'
          },
          tender_cepat : {
            no : 7,
            uraian : 'Tender Cepat'
          },
          swakelola : {
            no : 8,
            uraian : 'Swakelola'
          },

        },
  
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        autocomplete_db : '',
        // ====================================== CONTOH AUTOCOMPLETE ====================================
        
  
  
       
        list_data : [],
  
        page_first: 1,
        page_last: 0,
        page_limit : 8,
        cari_value: "",
        cek_load_data : true,
  
  
        mdl_print: false,

  
        FETCHING : FETCHING,
        UMUM : UMUM,
        DATA_MASTER : DATA_MASTER,
      }
    },
    methods: {
  
  
      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_DM_SUMBER_DANA + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value,
                page_limit : this.page_limit,
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
                console.log(res_data);
        });
      },
  

  
      selectData : function(data){
          this.form.id = data.id;
          this.form.uraian = data.uraian;
          this.form.kode = data.kode;
          this.form.keterangan = data.keterangan;
      },
  
  
      // ====================================== CONTOH eDOC ====================================
      onClickx(data){
        alert(data)
      },
      // ====================================== CONTOH eDOC ====================================
  
    // ====================================== CONTOH AUTOCOMPLETE ====================================
      autocomplete_db_filter : function (val, update) {
          update(() => {
            if (val === '') {}
            else {FETCHING.getContohAtocomplete(val)}
          })
      },
  
      // ====================================== CONTOH AUTOCOMPLETE ====================================
  
  
  
  
  
  
  
  
      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
              this.$q.notify({
                  message: message,
                  color: positive,
                  icon: icon,
                  position : 'top',
                  timeout: 500,
              })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },
  
          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },
  
          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },
  
          indexing : function(index){
              var idx = ((this.page_first-1)*this.page_limit)+index
              return idx
          },
  
  
      // ====================================== PAGINATE ====================================
  
  
  
  
  
  
  
    },
  
    mounted () {
      FETCHING.getContohAtocomplete('')
      this.getView();
    },
  }
  