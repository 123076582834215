
export default {
    props: ["data"],
    data() {
        return {
            key: ''
        }
    },

}
